import React, { lazy , Suspense } from 'react';
import {BrowserRouter,Route,Routes} from 'react-router-dom';


import './App.css';

import ToneLoader from './Components/commonComponents/Loaders/ToneLoader/index.js'







// <<<<<<!----------TELUGU ONE HOME PAGE ROUTES------------!>>>>>>>>>>>
const ToneHome = lazy(() => import('./Components/ToneHome/index.js') )
// <<<<<<!--------------------------------------!>>>>>>>>>>>>>>>>>>>>>>



//<<<<<<<!----------NEWS PAGE ROUTES------------!>>>>>>>>>>>
const News =lazy(() => import('./Components/News/index'))
const NewsMain = lazy(() => import('./Components/News/newsMain/index.js'))
const CategoryNews  = lazy(() => import('./Components/News/categoryNewsPage/index.js'))
const NewsContent = lazy(() => import('./Components/News/newsContent/index.js'))
const RssFeedsMain = lazy(() => import('./Components/News/rssFeeds/index.js'))
// <<<<<<!--------------------------------------!>>>>>>>>>>>


 
// <<<<<<!----------TMDB PAGE ROUTES------------!>>>>>>>>>>>
const Tmdb  = lazy(() => import ('./Components/Tmdb/index.js'))
const TmdbPages  = lazy(() => import ('./Components/TmdbPages/index'))
const TmdbMovieNews = lazy(() => import('./Components/TmdbPages/tmdbMovieNews/index'))
const  TmdbMovieReviews = lazy(() => import('./Components/TmdbPages/tmdbMovieReviews/index.js'))
const TmdbMovieReviewsMain  = lazy(() => import('./Components/TmdbPages/tmdbMovieReviews/tmdbMoviereviewsMain'))
const TmdbMovieReviewsDetails = lazy(() => import('./Components/TmdbPages/tmdbMovieReviews/tmdbMovieReviewsDetails'))
const TmdbMovieCelebrities = lazy(() => import('./Components/TmdbPages/tmdbMovieCelebrities/index.js'))
const  TmdbVideoGossips = lazy(() => import('./Components/TmdbPages/tmdbVideoGossips/index.js'))
const  TmdbVideoGossipsMain = lazy(() => import('./Components/TmdbPages/tmdbVideoGossips/tmdbVideoGossipsMain'))
const TmdbVideoGossipDetails = lazy(() => import('./Components/TmdbPages/tmdbVideoGossips/tmdbVideoGossipDetails/index.js'))
const  TmdbMovieNewsContent = lazy(() =>import('./Components/TmdbPages/tmdbMovieNewsContent/index.js'))
// <<<<<<!--------------------------------------!>>>>>>>>>>>



// <<<<<!----------MOVIES ROUTES------------!>>>>>>>>>>>
const Movies  = lazy(() => import('./Components/Movies/index.js'))
const  MoviesHome = lazy(() => import('./Components/Movies/moviesHome/index.js'))
const MoviesCategory = lazy(() => import('./Components/Movies/moviesCategory/index.js'))
const MovieDetails  = lazy(() => import('./Components/Movies/movieDetails/index.js'))
// <<<<<<!--------------------------------------!>>>>>>>>>>>




// <<<<<!----------PHOTOS ROUTES------------!>>>>>>>>>>>
const Photos =lazy(() =>   import('./Components/Photos/index.js'))
const PhotosHome =lazy(() =>   import('./Components/Photos/PhotosHome/index.js'))
const PhotosCategory =lazy(() => import('./Components/Photos/PhotosCategory/index.js'))
const PhotosCategoryPrimary=lazy(() => import('./Components/Photos/PhotosCategoryPrimary/index.js'))
const PhotosCategoryIndividual=lazy(() => import('./Components/Photos/PhotosCategoryIndividual/index.js'))
const PhotosCategoryShowCase=lazy(() => import('./Components/Photos/PhotosCategoryShowCase/index.js'))
// <<<<<<!--------------------------------------!>>>>>>>>>>>


// <<<<<!----------DEVOTIONAL ROUTES------------!>>>>>>>>>>>

const Devotional=lazy(()=> import('./Components/Devotional/index.js'))
const DevotionalHome=lazy(() => import('./Components/Devotional/DevotionalHome/index.js'))
const DevotionalContentsPage=lazy(() =>import('./Components/Devotional/DevotionalContentsPage/index.js'))
const DevotionalSubPages=lazy(() =>import('./Components/Devotional/DevotionalSubPages/index.js'))
const DevotionalVideoList=lazy(() => import('./Components/Devotional/DevotionalVideoList/index.js'))
const DevotionalVideos=lazy(() => import('./Components/Devotional/DevotionalVideos/index.js'))
const DevotionalVideo=lazy(() => import('./Components/Devotional/DevotionalVideo/index.js'))
const DevotionalContent=lazy(() => import('./Components/Devotional/DevotionalContent/index.js'))
const DevotionalWeekly=lazy(() => import('./Components/Devotional/DevotionalWeekly/index.js'))
const DevotionalTithi=lazy(() => import('./Components/Devotional/DevotionalTithi/index.js'))
// <<<<<<!--------------------------------------!>>>>>>>>>>>



// <<<<<!----------GRANDALAYAM ROUTES------------!>>>>>>>>>>>
 const Grandalayam =lazy(() => import('./Components/Grandalayam/index.js'))
 const GrandalayamHome =lazy(() => import('./Components/Grandalayam/GrandalayamHome/index.js'))
 const GrandalayamCategory=lazy(() => import('./Components/Grandalayam/GrandalayamCategory/index.js'))
 const GrandalayamNovels=lazy(() => import('./Components/Grandalayam/GrandalayamCategory/GrandalayamNovels/index.js'))
 const GrandalyamNovelDetails=lazy(() =>import('./Components/Grandalayam/GrandalayamNovelDetails/index.js'))
 const GrandalayamWriters=lazy(() => import('./Components/Grandalayam/GrandalayamWriters/index.js'))
 // <<<<<<!--------------------------------------!>>>>>>>>>>>


// <<<<<!---------- VANITHA ROUTES------------!>>>>>>>>>>>
const Vanitha=lazy(() =>import('./Components/Vanitha/index.js'))
const VanithaHome=lazy(() => import('./Components/Vanitha/VanithaHome/index.js'))
const VanithaCategory=lazy(() => import('./Components/Vanitha/VanithaCategoryPage/index.js'))
const VanithaContent=lazy(() => import('./Components/Vanitha/VanithaContent/index.js'))

//<<<<<<!------------------------------------------!>>>>>>>>>>>


// <<<<<!---------- VIDEOS ROUTES------------!>>>>>>>>>>>
const Videos=lazy(() => import('./Components/Videos/index.js'))
const VideosHome=lazy(() => import('./Components/Videos/videosHome/index.js'))
const VideosCategoryPage=lazy(() => import('./Components/Videos/videosCategory/index.js'))
const VideosIndividualCategory=lazy(() => import('./Components/Videos/videosCategory/videosIndividualCategory.js'))
const VideosContent= lazy(() => import('./Components/Videos/videosContent/index.js')) 
//<<<<<<!------------------------------------------!>>>>>>>>>>>

// <<<<<!---------- RECIPES ROUTES------------!>>>>>>>>>>>
const Recipes=lazy(() => import('./Components/Recipes/index.js'))
const RecipesHome=lazy(() => import('./Components/Recipes/recipesHome/index.js'))
const RecipesCategoryPage=lazy(() => import('./Components/Recipes/recipesCategoryPage/index.js'))
const RecipesContent=lazy(() =>  import('./Components/Recipes/recipesContent/index.js'))

//<<<<<<!------------------------------------------!>>>>>>>>>>>

// <<<<<!---------- HEALTH ROUTES------------!>>>>>>>>>>>
const Health=lazy(() => import('./Components/Health/index.js'))
const HealthHome=lazy(() => import('./Components/Health/HealthHome/index.js'))
const HealthCategory=lazy(() => import('./Components/Health/HealthCategory/index.js'))
const VideoCentreCategorySection=lazy(() => import('./Components/Health/HealthCategory/videoCentreCategorySection.js'))
const DoctorsCategorySection=lazy(() => import('./Components/Health/HealthCategory/doctorCategorySection.js'))
const HealthContent =lazy(() => import('./Components/Health/HealthContent/index.js'))
//<<<<<<!------------------------------------------!>>>>>>>>>>>

// <<<<<!---------- SHORT FILMS ROUTES------------!>>>>>>>>>>>

const ShortFilms=lazy(() => import('./Components/ShortFilms/index.js'))
const ShortFilmsHome=lazy(() => import('./Components/ShortFilms/ShortFilmsHome/index.js'))

//<<<<<<!------------------------------------------!>>>>>>>>>>>




// <<<<<<!----------PAGE NOT FOUND ROUTES------------!>>>>>>>>>>>>>
const PageNotFound=lazy(() => import ('./Components/NotFound/index.js'))
//<<<<<<!------------------------------------------!>>>>>>>>>>>>>>





  




function App() { 
  
  return (
    <div className="App d-flex flex-column align-items-center">
        <BrowserRouter>
        <Suspense fallback={<ToneLoader />}>  
        <Routes>

                  {/*********************************** ToneHome *************************************/}
                    <Route exact  path="/" element={<ToneHome />} />


                  {/*********************************** NEWS *************************************/}
                    <Route path="/news" element={<News />}>
                                    <Route index  element={<NewsMain />} />
                                    <Route path="/news/latestnews" element={<CategoryNews catId={25} cat={"LatestNews"} />} />
                                    <Route path="/news/politics" element={<CategoryNews catId={39} cat={"Politics"} />} />
                                    <Route path="/news/lifestyle" element={<CategoryNews catId={35} cat={"lifestyle"} />} />
                                    <Route path="/news/healthnews" element={<CategoryNews catId={34} cat={"healthnews"} />} />
                                    <Route path="/news/analyticsnews" element={<CategoryNews catId={45} cat={"analyticsnews"} />} />
                                    <Route path="/news/content/:contentText"   element={<NewsContent />} />
                                    <Route path="/news/rssdetails"  element={<RssFeedsMain  />} />
                    </Route>



                  {/*********************************** TMDB *************************************/}
                      <Route path="/tmdb" element={<Tmdb />}  />

                     <Route path="/tmdb/pages" element={<TmdbPages />}>
                      <Route path="latestnews" element={<TmdbMovieNews subRoot={"Latest News"} catId={"LatestNews"} />} />
                      <Route path="featurednews" element={<TmdbMovieNews subRoot={"Featured News"} catId={"FeaturedNews"} />} />
                      <Route path="bollywoodnews" element={<TmdbMovieNews subRoot={"Bollywood News"} catId={"BollyWoodNews"} />} />
                      <Route path="gossips" element={<TmdbMovieNews subRoot={"GOSSIPS"} catId={"Gossips"}/>} />
                      <Route path="tvnews" element={<TmdbMovieNews subRoot={"Tv News"}  catId={"TvNews"} />} />
                      <Route path="reviews" element={<TmdbMovieReviews />} >
                         <Route index element={<TmdbMovieReviewsMain />} />
                         <Route path="details" element={<TmdbMovieReviewsDetails />} />
                     </Route>
                      
                    <Route path="celebrities" element={<TmdbMovieCelebrities />} />
                      <Route path="videogossips" element={<TmdbVideoGossips  />} >
                        <Route index element={<TmdbVideoGossipsMain />} />
                        <Route path="details" element={<TmdbVideoGossipDetails />} />
                      </Route>

                      <Route path="latestnews/content" element={<TmdbMovieNewsContent subRoot={"Latest News"} />} />
                      <Route path="bollywoodnews/content" element={<TmdbMovieNewsContent subRoot={"Latest News"} />} />
                      <Route path="featurednews/content" element={<TmdbMovieNewsContent subRoot={"Latest News"} />} />
                      <Route path="tvnews/content" element={<TmdbMovieNewsContent subRoot={"Latest News"} />} />
                              </Route>


                  {/*********************************** MOVIES *************************************/}
                    <Route path="/movies" element={<Movies />}>
                        <Route index element={<MoviesHome  homeActiveLink={"Home"} />} />
                        <Route path="latest" element={<MoviesCategory homeActiveLink={"Latest"}  />}  />   
                        <Route path="action" element={<MoviesCategory homeActiveLink={"Action"}  />}  />   
                        <Route path="classics" element={<MoviesCategory homeActiveLink={"Classics"} />}  />   
                        <Route path="comedy" element={<MoviesCategory  homeActiveLink={"Comedy"} />}  />   
                        <Route path="family" element={<MoviesCategory homeActiveLink={"Family"}  />}  />   
                        <Route path="romantic" element={<MoviesCategory homeActiveLink={"Romantic"}  />}  />   
                        <Route path="love" element={<MoviesCategory  homeActiveLink={"Love"} />}  />   
                        <Route path="shortfilms" element={<MoviesCategory homeActiveLink={"ShortFilms"}   />}  />    
                        <Route path="latest/details/:id" element={<MovieDetails homeActiveLink={"Latest"} />}  />   
                        <Route path="action/details/:id" element={<MovieDetails homeActiveLink={"Action"}  />}  />   
                        <Route path="classics/details/:id" element={<MovieDetails homeActiveLink={"Classics"} />}  />   
                        <Route path="comedy/details/:id" element={<MovieDetails homeActiveLink={"Comedy"}  />}  />   
                        <Route path="family/details/:id" element={<MovieDetails  homeActiveLink={"Family"} />}  />   
                        <Route path="romance/details/:id" element={<MovieDetails homeActiveLink={"Romantic"}  />}  />   
                        <Route path="love/details/:id" element={<MovieDetails />} homeActiveLink={"Love"}   />   
                        <Route path="shortfilms/details/:id" element={<MovieDetails homeActiveLink={"ShortFilms"} />}/>   
            
                    </Route>

                  {/*********************************** PHOTOS *************************************/}
                    <Route path="/photos" element={<Photos />}>

                        <Route index element={<PhotosHome />} />
                        <Route  path='/photos/actress' element={<PhotosCategory  />} >
                              <Route index  element={<PhotosCategoryPrimary />} />
                              <Route path="/photos/actress/individual"  element={<PhotosCategoryIndividual />} />
                              <Route path="/photos/actress/individualShowCase" element={<PhotosCategoryShowCase />} />
                        </Route>
                        <Route  path='/photos/actors' element={<PhotosCategory  />} >
                              <Route index  element={<PhotosCategoryPrimary />} />
                              <Route path="/photos/actors/individual"  element={<PhotosCategoryIndividual />} />
                              <Route path="/photos/actors/individualShowCase" element={<PhotosCategoryShowCase />} />
                        </Route>
                        <Route  path='/photos/events' element={<PhotosCategory   />} >
                                    <Route index  element={<PhotosCategoryPrimary />} />
                              <Route path="/photos/events/individual"  element={<PhotosCategoryIndividual />} />
                              <Route path="/photos/events/individualShowCase" element={<PhotosCategoryShowCase />} />
                        </Route>
                        <Route  path='/photos/wallpapers' element={<PhotosCategory  />} >
                                    <Route index  element={<PhotosCategoryPrimary />} />
                              <Route path="/photos/wallpapers/individual"  element={<PhotosCategoryIndividual />} />
                              <Route path="/photos/wallpapers/individualShowCase" element={<PhotosCategoryShowCase />} />
                        </Route>
                        <Route  path='/photos/kingsize' element={<PhotosCategory  />} >
                              <Route index  element={<PhotosCategoryPrimary />} />
                              <Route path="/photos/kingsize/individual"  element={<PhotosCategoryIndividual />} />
                              <Route path="/photos/kingsize/individualShowCase" element={<PhotosCategoryShowCase />} />
                        </Route>
                        <Route  path='/photos/others' element={<PhotosCategory  />} >
                                    <Route index  element={<PhotosCategoryPrimary />} />
                              <Route path="/photos/others/individual"  element={<PhotosCategoryIndividual />} />
                              <Route path="/photos/others/individualShowCase" element={<PhotosCategoryShowCase />} />
                        </Route>
                        <Route  path='/photos/movies' element={<PhotosCategory  />} >
                              <Route index  element={<PhotosCategoryPrimary />} />
                              <Route path="/photos/movies/individual"  element={<PhotosCategoryIndividual />} />
                              <Route path="/photos/movies/individualShowCase" element={<PhotosCategoryShowCase />} />
                        </Route>
                    </Route>

                  {/*********************************** DEVOTIONAL *************************************/}
                    <Route path="/devotional" element={<Devotional />}>

                        <Route index element={<DevotionalHome />} />
                        
                        <Route path="contents/" element={<DevotionalContentsPage />} />
                              
                        <Route path="subpages/" element={<DevotionalSubPages />} />

                        <Route path="videolist/" element={<DevotionalVideoList />} />

                        <Route path="videos/" element={<DevotionalVideos />} />

                        <Route path="video/" element={<DevotionalVideo />} />

                        <Route path="content/" element={<DevotionalContent />} />
                        
                        <Route path="weekly/" element={<DevotionalWeekly />} />

                        <Route path="tithi/" element={<DevotionalTithi />} />
                        

                    </Route> 

                  {/*********************************** GRANDALAYAM *************************************/}
                    <Route path="/grandalayam" element={<Grandalayam />}>
                        <Route index element={<GrandalayamHome />} />
                        <Route path='novels'  element={<GrandalayamCategory />} >
                              <Route index element={<GrandalayamNovels />} />
                              <Route path='details/:novel' element={<GrandalyamNovelDetails />} />
                        </Route>
            
                        <Route path='writers/publications' element={<GrandalayamWriters />} />
                        <Route path='writers/history' element={<GrandalayamWriters />} />
                        <Route path='writers/individual' element={<GrandalayamWriters />} />
                        

                    </Route>

                  {/*********************************** VANITHA *************************************/}
                    <Route path="/vanitha" element={<Vanitha />}>
                        <Route index element={<VanithaHome />}   />

                        <Route path="fashion" element={<VanithaCategory />} />
                        <Route path="beauty" element={<VanithaCategory />} />
                        <Route path="health" element={<VanithaCategory />} />
                        <Route path="fitness" element={<VanithaCategory />} />
                        <Route path="yoga" element={<VanithaCategory />} />
                        <Route path="babyCare" element={<VanithaCategory />} />
                        <Route path="ladiesSpecial" element={<VanithaCategory />} />
                        <Route path="muggulu" element={<VanithaCategory />} />

                        <Route path="content" element={<VanithaContent />} />
                    </Route>

                  {/*********************************** VIDEOS *************************************/}
                  <Route path="/videos" element={<Videos />} >
                         <Route index element={<VideosHome/>}   />
                         <Route path="page" element={<VideosCategoryPage />} >
                              <Route path='recentuploads' element={<VideosIndividualCategory />} />
                              <Route path='trailers' element={<VideosIndividualCategory />} />
                              <Route path='comedy' element={<VideosIndividualCategory />} />
                              <Route path='events' element={<VideosIndividualCategory />} />
                              <Route path='songs' element={<VideosIndividualCategory />} />
                              <Route path='rhymes' element={<VideosIndividualCategory />} />
                              <Route path='animatedstories' element={<VideosIndividualCategory />} />
                              <Route path='e-learning' element={<VideosIndividualCategory />} />
                              <Route path='health' element={<VideosIndividualCategory />} />
                              <Route path='toriliveshows' element={<VideosIndividualCategory />} />
                              <Route path='recipes' element={<VideosIndividualCategory />} /> 
                         </Route>
                         <Route path="webseries" element={<VideosIndividualCategory />} />
                         <Route path="comedy/funbucket" element={<VideosIndividualCategory />} />
                         <Route path="sub/mehndi" element={<VideosIndividualCategory />} />
                         <Route path="content/:id" element={<VideosContent />} />
                  </Route>

                  {/*********************************** RECIPES *************************************/}
                   <Route path="/recipes" element={<Recipes />} >
                         <Route index element={<RecipesHome/>}   />
                         <Route path="contents/vegetarian" element={<RecipesCategoryPage />} />
                         <Route path="contents/nonVegetarian" element={<RecipesCategoryPage />} />
                         <Route path="contents/sweetsNDesserts" element={<RecipesCategoryPage />} />
                         <Route path="contents/rice" element={<RecipesCategoryPage />} />
                         <Route path="contents/pickles" element={<RecipesCategoryPage />} />
                         <Route path="contents/appetizers" element={<RecipesCategoryPage />} />
                         <Route path="contents/beverages" element={<RecipesCategoryPage />} />
                         <Route path="contents/milletFood" element={<RecipesCategoryPage />} />
                         <Route path="contents/latestrecipes" element={<RecipesCategoryPage />} />

                         <Route path='content' element={<RecipesContent />} />

                   </Route>

                  {/*********************************** RECIPES *************************************/}
                   <Route path='/health' element={<Health />} >
                         <Route index element={<HealthHome />} />
                         <Route path="contents/everyday-health" element={<HealthCategory />} />
                         <Route path="contents/staying-healthy" element={<HealthCategory />} />
                         <Route path="contents/diet-and-fitness" element={<HealthCategory />} />
                         <Route path="contents/yoga" element={<HealthCategory />} />
                         <Route path="contents/video-centre" element={<VideoCentreCategorySection />} />
                         <Route path="contents/news-and-experts" element={<DoctorsCategorySection />} />

                         <Route path="content/:id" element={<HealthContent />} />
                   </Route> 


                  {/*********************************** RECIPES *************************************/}
                   <Route path="/shortFilms" element={<ShortFilms />}>
                          <Route index  element={<ShortFilmsHome />} ></Route>
                          
                   </Route>


             { /*
              <Route path="/astrology" element={<Astrology />}></Route>
              <Route path="/comedy" element={<Comedy />}></Route>
              <Route path="/health" element={<Health />}></Route>
              <Route path="/greetings" element={<Greetings/>}></Route>
              <Route path="/charity" element={<Charity />}></Route>
              <Route path="/nri" element={<Nri />}></Route>
              <Route path="/help" element={<Help />}></Route>
              <Route path="/siteMap" element={<SiteMap />}></Route>
              <Route path="/siteTour" element={<SiteTour />}></Route>
              <Route path="/recommend" element={<Recommend />}></Route>
              <Route path="/faq" element={<Faq />}></Route>
              <Route path="/contactInfo" element={<ContactInfo />}></Route>
              <Route path="/feedback" element={<FeedBack/>}></Route>
               */}

              <Route path="*" Component={PageNotFound} /> 
    
          </Routes>
        
          </Suspense>
       </BrowserRouter>
     
    </div>
  );
}



export default App;














